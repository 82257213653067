<template>
  <div>
    <div class="login-wrapper">
      <div class="login-bg-position login-bg">
          <div class="bg-overlay"></div>
          <div class="shape">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 160">
                <polygon points="0 160 1280 160 0 0 0 160"/><polygon points="1280 160 2560 160 2560 0 1280 160"/>
              </svg>                
          </div>
      </div>

      <div class="login-content">
        <div class="card">
          <div class="card-header">
            <img src="/img/logo_black.png" alt="X-GOLF">
            <h6>{{ $t("page.login.fitting_login") }}</h6>
          </div>          
          <b-form name="login_frm" @submit="onSubmit">
            <input
              type="text"
              class="form-control form-control-lg mb-3"
              :placeholder="$t('title.common.id')"
              aria-label=""
              v-model="form.id"
              name="id"
              autocomplete="off"
            />
            <input
              type="password"
              class="form-control form-control-lg mb-3"
              :placeholder="$t('title.common.password')"
              aria-label=""
              v-model="form.pw"
              name="pw"
              autocomplete="off"
            />
            <div class="d-block mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                value="y"
                name="remember_yn"
                id="flexCheck"
                v-model="form.remember_yn"
                true-value="y"
                false-value="n"
                :checked="form.remember_yn === 'y'"
              />
              <label class="form-check-label" for="flexCheck">{{ $t("text.login.remember_id") }} </label>
            </div>
            <input class="btn btn-primary btn-lg w-100" type="submit" :value="$t('button.login.login')">
          </b-form>
        </div>
      </div>        

      <div class="footer">
        &copy; 2023 X-GOLF Co., Ltd.
      </div>
    </div>
    
  </div>
</template>

<script>
import ax from "@/api/auth";
import _ from "lodash";
//import location from "@/components/Location";
//import { loadScript } from "vue-plugin-load-script";

export default {
  name: "Login",
  data() {
    return {
      form: {
        id: localStorage.getItem("remember_id") || "",
        pw: "",
        remember_yn: localStorage.getItem("remember_yn") || "",
      },
      loginError: false,
      googleParams: { client_id: process.env.VUE_APP_SOCIAL_GOOGLE_CLIENT_ID },
    };
  },
  components: {
    location,
  },
  created() {
    if (this.$store.state.user !== null) {
      // this.$router.push('/dashboard')
    }
    this.form = _.cloneDeep(this.form);

    // loadScript(
    //   `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js`
    // ).then(() => {
    //   // if (!window.APPLE) apple.init();
    // });
    // if (!window.FB) facebook.init();
  },

  methods: {
    vueAppleLogin() {
      this.$refs.apple.$el.click();
    },
    onSubmit(event) {
      event.preventDefault();
      if (this.form.id === "") {
        alert(this.$i18n.t("alert.common.enter_id"));
        return false;
      }

      if (this.form.pw === "") {
        alert(this.$i18n.t("alert.common.check_password"));
        return false;
      }

      ax.getToken(this.form, (flag, data) => {
        if (flag) {
          this.$router.push("/");
        } else {
          this.resetForm();
          alert(data.message);
          if (data.code === 301) {
            if (confirm(this.$i18n.t("alert.common.receive_verification_email"))) {
              ax.verifyEmail(this.form, (flag, code, message) => {
                if (flag) {
                  if (code === 200) {
                    alert(message);
                    // this.$router.push('/login')
                    this.$router.push({ name: "Login" });
                    return true;
                  } else {
                    // alert(code)
                    //alert('code가 200이 아님')
                    return false;
                  }
                } else {
                  alert(message);
                  return false;
                }
              });
            }
          }
        }
      });
    },
    resetForm() {
      this.form = _.cloneDeep(this.form);
    },
  },
};
</script>